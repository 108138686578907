export function getCurrentPhase() {
    if(isApplyAvailable()){
        return 'PHASE_3' // hardcoded to PHASE_3 since all the previous conditional statements are commented out
    } 
    else {
        return "CLOSED"
    }
}


export function isApplyCButtonAvailable() {
    const applyDeadline = new Date('2025-03-02T15:00:00Z'); // Mark 3 2025 00:00 KST (2차 모집 종료)
    const now = new Date();

    if (now < applyDeadline) {
        return true
    } else { 
        return false
    }
}

export function isApplyKButtonAvailable() {
    const applyDeadline = new Date('2025-03-23T15:00:00Z'); // Mar 24 2025 00:00 KST (2차 모집 종료)
    const now = new Date();

    if (now < applyDeadline) {
        return true
    } else { 
        return false
    }
}

export function isApplyAvailable() {
    const applyDeadline = new Date('2025-03-23T15:00:00Z'); // Mar 24 2025 00:00 KST (2차 모집 종료)
    const now = new Date();

    if (now < applyDeadline) {
        return true
    } else { 
        return false
    }

}