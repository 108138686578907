import { URLManager } from './URLManager';
import { ContentManager } from './ContentManager';
import END_OF_APPLICATION from '../messages/end-of-application.json';
import { isApplyCButtonAvailable, isApplyKButtonAvailable } from './scheduleHelper'

function disablePinchZoom() {
    document.addEventListener(
        'touchstart',
        function (e) {
            if (e.touches.length > 1) {
                e.preventDefault();
            }
        },
        { passive: false }
    );
    document.addEventListener(
        'touchmove',
        function (e) {
            if (e.scale && e.scale !== 1) {
                e.preventDefault();
            }
        },
        { passive: false }
    );
}

disablePinchZoom();

const urlManager = new URLManager();
const contentManager = new ContentManager(urlManager);

// Define the handler function in the global scope
function handleClickOnApplyButton() {
    window.app.switchTab('second');
}

function navToApply(applyLang) {
    if(isApplyCButtonAvailable()) {
        window.app.showApply(applyLang);
    } else {
        const locale = urlManager.getParam('locale');
        alert(END_OF_APPLICATION[locale]);
    }
}

function navToApplyK() {
    if(isApplyKButtonAvailable()) {
        window.app.showApply('ko');
    } else {
        const locale = urlManager.getParam('locale');
        alert(END_OF_APPLICATION[locale]);
    }
}

function navToApplyC() {
    if(isApplyCButtonAvailable()) {
        window.app.showApply('cn');
    } else {
        const locale = urlManager.getParam('locale');
        alert(END_OF_APPLICATION[locale]);
    }
}

// Make it available globally
window.handleClickOnApplyButton = handleClickOnApplyButton;
window.navToApplyK = navToApplyK;
window.navToApplyC = navToApplyC;

window.app = {
    switchTab: (tab) => {
        urlManager.setParam('tab', tab);
        urlManager.setParam('applyLang', null);
        contentManager.updateTabBarImage(tab); // Updated method name
        contentManager.showContent(tab, null);
    },
    showApply: (lang) => {
        contentManager.storePreviousState();
        urlManager.setParam('applyLang', lang);
        contentManager.showContent(null, lang);
        contentManager.updateBackButton(lang);
    },
};
