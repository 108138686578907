import closeImgKo from '../../images/closed/closed-ko.webp';
import closeImgEn from '../../images/closed/closed-en.webp';
import closeImgJa from '../../images/closed/closed-ja.webp';
import closeImgCnZh from '../../images/closed/closed-zh-cn.webp';
import closeImgCnTw from '../../images/closed/closed-zh-tw.webp';

export const closedImages = {
    contentImages: {
        closed: {
            en: closeImgEn,
            ja: closeImgJa,
            ko: closeImgKo,
            'zh-CN': closeImgCnZh,
            'zh-TW': closeImgCnTw,
        }
    }
};

export function getClosedImage(locale) {
    return closedImages.contentImages.closed[locale] || closedImages.contentImages.closed.en;
}

export function preloadClosedImages() {
    const promises = [];
    
    Object.values(closedImages.contentImages.closed).forEach(src => {
        const img = new Image();
        const promise = new Promise((resolve) => {
            img.onload = resolve;
            img.onerror = resolve; // Still resolve even on error to avoid blocking
            img.src = src;
        });
        promises.push(promise);
    });
    
    return Promise.all(promises);
}